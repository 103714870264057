export default function (id) {
    let window = id ? `reu.${id}` : 'reu.new'
    !this.$uloc.window.get(window) && this.$uloc.window.new({
        wid: window,
        title: 'Réu' + (id ? ` #${id}` : ''),
        width: '1000',
        height: '700',
        minHeight: '500px',
        backdrop: false,
        clickOutside: false,
        windowClass: 'erp-window',
        contentClass: 'overflow-hidden',
        props: {
            id
        }
    }, () => import('./Reu'))
        .then((wid) => {
            console.log(wid)
            this.$uloc.window.listen(wid, {
                update: (wid, val) => {
                    console.log('Atualizado: ', val)
                    this.updateList && this.updateList(val)
                }
            })
        }) // return wid
}
