<template>
  <helper-input-btn>
    <erp-input
        ref="input"
        @input="__set"
        slot="input"
        simple-border
        v-model="description"
        @keydown.enter="searchAutor"
        readonly
    >
    </erp-input>
    <a @click="searchAutor"><i class="erp-icon search min"></i> </a>
    <u-icon name="plus-circle" type="fa" icon-style="light" class="m-l-xs cursor-pointer" @click.native="abrirCadastro" title="Cadastrar autor"/>
  </helper-input-btn>
</template>

<script>
import InputMixin from '../../../../../reuse/input/mixin'
import {HelperInputBtn} from 'uloc-vue-plugin-erp'
import searchAutor from '../window/search/SearchAutor'
import windowAutor from '../window/windowAutor'

export default {
  name: 'AutorInput',
  mixins: [InputMixin],
  props: ['router'],
  data () {
    return {
      findedData: null,
      description: null
    }
  },
  components: {HelperInputBtn},
  methods: {
    __updateInternal() {
      this.updateInternal((v) => v.id + ' - ' + v.descricao)
      this.$emit('selecionaAutor', this.findedData)
    },
    abrirCadastro () {
      let router = this.$router || this.router
      let routeData = router.resolve({name: 'pessoas', query: {tags: 'autor'}})
      window.open(routeData.href, '_blank')
    },
    searchAutor,
    windowAutor
  }
}
</script>
