<script>
import {
  WindowContent,
  WindowBody,
  WindowFooter,
  ErpTabItem,
  ErpTabs,
  ErpSField,
  ErpInput,
  ErpSelect,
  ErpCheckbox,
} from 'uloc-vue-plugin-erp'
import WindowLoading from "../../../../layout/window/Loading"
import ERow from "../../../../layout/components/Row"
import ECol from "../../../../layout/components/Col"
import JuizInput from "../../juiz/input/JuizInput"
import {list} from "@/domain/processos/services/comarca"
// import {list as listJuizos} from "../../../../../domain/processos/services/juizo"
import {list as listVaras} from "../../../../../domain/processos/services/vara"
import {list as listTribunais} from "../../../../../domain/processos/services/tribunal"
import {
  getClassificacao as listClassificacoes,
  getStatus as listStatuses
} from "../../../../../domain/processos/services/status";
import {
  newProcesso,
  find,
  updateProcesso,
  adicionaAdvogadoAoProcesso,
  updatePartesProcesso,
  listTiposParte,
  getInformacoes
} from "@/domain/processos/services"
// import TableBens from "../include/tables/TableBens"
// import BemInput from "../../../../bem/helpers/input/BemInput"
import EscrivaoInput from "../../escrivao/input/EscrivaoInput"
import ReuInput from "../../reu/input/ReuInput"
import AutorInput from "../../autor/input/AutorInput"
import TipoProcessoSelect from "../include/TipoProcessoSelect"
import AdvogadoInput from "../../advogado/input/AdvogadoInput"
import {CONFIG_ENTIDADES} from "../config/config"
import UfSelect from "../../../../remocao/components/include/UfSelect"
import {VMoney} from 'v-money'
import {REAL_BRL, convertRealToMoney} from '@/utils/money'
import ClienteProcessoInput from "../../cliente/input/ClienteProcessoInput"
import TablePessoaProcesso from "../include/tables/TablePessoaProcesso"
import {isReadyToSubmit, turnCamelCaseStringReadable} from "../utils/utils"
import ProcessoMixins from "../mixins/ProcessoMixins"
import WindowSuccess from "@/components/layout/window/Success"
import TimerCloseWindow from "@/components/layout/window/TimerClose"

// @TODO <RAPHAEL> <URGENTE> - Mudar seleção da comarca para o ErpSearchInput, pois pode existir centenas de registros, como por exemplo em MG ou SP
// @TODO <RAPHAEL> <URGENTE> - Poder selecionar responsável (pessoa) no cadastro do processo.

const MAX_LIMIT_LOAD_SELECTS = 5000

export default {
  name: 'ProcessoWindow',
  props: ['id', 'router'],
  mixins: [ProcessoMixins],
  directives: {money: VMoney},
  components: {
    TimerCloseWindow,
    WindowSuccess,
    TablePessoaProcesso,
    ClienteProcessoInput,
    UfSelect,
    AdvogadoInput,
    TipoProcessoSelect,
    AutorInput,
    ReuInput,
    EscrivaoInput,
    // BemInput,
    // TableBens,
    JuizInput,
    WindowContent,
    WindowBody,
    WindowFooter,
    ErpTabItem,
    ErpTabs,
    ErpSField,
    ErpInput,
    ErpSelect,
    ErpCheckbox,
    ERow,
    ECol,
    WindowLoading
  },
  data () {
    return {
      money: REAL_BRL,
      processo: {
        id: null,
        tribunal: null,
        numero: null,
        numeroAntigo: null,
        digitalizado: false,
        penhora: false,
        publicadoSite: false,
        enderecoDocumentoDigitalizado: null,
        uf: null,
        cidade: null,
        valorCausa: null,
        ativarRobo: null,
        status: null,
        tipo: null,
        juizo: null,
        vara: null,
        cliente: null,
        autors: [],
        advogadoAutors: [],
        reus: [],
        advogadoReus: [],
        juiz: [],
        // bens: [],
        escrivaos: [],
      },
      loading: !!this.id,
      errors: null,
      status: null,
      tabActive: 'principal',
      idJuiz: null,
      idEscrivao: null,
      idReu: null,
      idAutor: null,
      idAdvogado: null,
      idAdvogadoA: null,
      idCliente: null,
      foo: null,
      success: false,
      cache: {
        tribunais: {
          list: [],
          loading: false,
          selected: null
        },
        juizos: {
          list: [],
          loading: false,
          selected: null
        },
        comarcas: {
          list: [],
          loading: false,
          selected: null
        },
        varas: {
          list: [],
          loading: false,
          selected: null
        },
        classificacoes: {
          list: [],
          loading: false,
          selected: null
        },
        statuses: {
          list: [],
          loading: false,
          selected: null
        }
      }
    }
  },
  watch: {
    'cache.classificacoes.list': function (v) {
      if (!v?.length || v.length > 1 || this.cache.classificacoes.selected !== null) return
      this.processo.classificacao = v[0].value
    }
  },
  created () {
    this.loadTiposParte()
    this.loadTribunais()
    this.loadStatuses()
    // this.loadJuizos()
  },
  mounted() {
    this.load()
  },
  methods: {
    load () {
      this.id && find(this.id)
          .then(({data}) => {
            if (data.vara && data.vara.comarca) {
              this.cache.comarcas.selected = data.vara.comarca.id
            }
            // this.tribunal = data.vara.comarca.tribunal.id
            data.tribunal = data.tribunal?.id
            data.vara = data.vara?.id
            data.juizo = data.juizo?.id
            data.status = data.status?.id
            data.classificacao = data.classificacao?.id
            this.processo = data
            this.montaPartes(data.partes)
            this.loadComarcasByTribunal(data.tribunal)
            this.loadVarasByComarca(this.cache.comarcas.selected)
            this.loadClassificacoesByStatus(data.status)
          })
          .catch((error) => {
            this.$uloc.dialog({
              title: 'Falha ao carregar!',
              color: 'negative',
              message: 'Não conseguimos carregar os dados do processo, entre em contato com o administrador.'
            })
            this.errors = error.data
            console.error(error, error.data)
          })
          .finally(() => {
            this.loading = false
          })
    },
    loadTiposParte () {
      listTiposParte(MAX_LIMIT_LOAD_SELECTS, 1, '')
          .then (response => {
            console.log('tiposParte', response.data)
            this.tiposParte = response.data.result
          })
          .catch((error) => {
            this.alertApiError(error)
            console.log(error)
          })
    },
    loadTribunais () {
      const parameters = [MAX_LIMIT_LOAD_SELECTS, 1, ''];
      this.loadCache('tribunais', listTribunais, parameters)
    },
    loadStatuses () {
      this.loadCache('statuses', listStatuses)
    },
    loadClassificacoesByStatus (status) {
      this.loadCache('classificacoes', listClassificacoes, [status])
    },
    loadComarcasByTribunal (id) {
      const parameters = [MAX_LIMIT_LOAD_SELECTS, 1, `&tribunal=${id}`]
      const callback = c => ({label: `${c.codigo} - ${c.nome}`, value: c.id})
      this.loadCache('comarcas', list, parameters, callback)
    },
    loadJuizos () {
      const parameters = [MAX_LIMIT_LOAD_SELECTS, 1, '']
      this.loadCache('juizos', listJuizos, parameters)
    },
    loadVarasByComarca (v) {
      const parameters = [MAX_LIMIT_LOAD_SELECTS, 1, `&comarca=${v}`]
      this.loadCache('varas', listVaras, parameters)
    },
    changeTab ({tab}) {
      this.tabActive = tab
    },
    save () {
      const processo = JSON.parse(JSON.stringify(this.processo))
      const requiredFields = ['numero', 'tribunal', 'status', 'vara']
      const callback = field => {
        this.$uloc.notify({
          type: 'negative',
          message: `Preencha o campo "${turnCamelCaseStringReadable(field)}"`
        });
      }
      if (!isReadyToSubmit(processo, requiredFields, callback)) return
      this.loading = true
      this.status = `${this.processo.id ? 'Atualizando' : 'Cadastrando'} informações, aguarde...`
      const relationships = ['cliente', 'responsavel', 'tribunal', 'status', 'juizo', 'classificacao', 'vara']
      for (const relationship of relationships) {
        if (typeof processo[relationship] !== 'undefined') {
          processo[relationship] = processo[relationship]?.id || processo[relationship]
        }
      }
      /*processo.bens = []
      for (const bem of this.processo.bens) {
        processo.bens.push(bem?.id)
      }*/
      processo.valorCausa = convertRealToMoney(processo.valorCausa)
      processo.numero = processo.numero?.replace(/\D/g, '')
      processo.numeroAntigo = processo.numeroAntigo?.replace(/\D/g, '')

      let partes = ['juiz', 'autors', 'advogadoAutors', 'reus', 'advogadoReus', 'escrivaos'];
      partes = partes.reduce((acc, name) => [...acc, ...(this.processo[name] || [])], []);
      processo.partes = []
      for (const pessoa of partes) {
        let parteTmp = {}
        if (typeof pessoa.tmpParteInfo === 'string') {
          parteTmp.pessoa = {id: pessoa.id}
          parteTmp.tipo = this.tiposParte.find(t => t.codigo === pessoa.tmpParteInfo)?.id
          parteTmp.processo = processo.id
        } else {
          parteTmp = pessoa.tmpParteInfo
          parteTmp.tipo = parteTmp.tipo.id
        }
        processo.partes.push(parteTmp)
      }

      const method = this.processo.id ? updateProcesso(this.processo.id, processo) : newProcesso(processo)
      method
          .then(response => {
            processo.id = this.processo.id = response.data.id
            this.status = 'Processando as partes do processo...'
            updatePartesProcesso(processo)
                .then (response => {
                  const cbSuccess = () => {
                    this.success = true
                    this.$nextTick(() => {
                      this.loading = false
                      this.$uloc.window.emit(this.$root.wid, 'update', this.props)
                      this.$nextTick(() => {
                        this.$refs.timer.start()
                      })
                    })
                  }
                  cbSuccess()
                })
            if(this.processo.tribunal === "TJMG") {
              this.callRoboProcesso(this.processo)
                .then(() => {
                })
                .catch((err) => {
                  this.alertApiError(err)
                })
            }
          })
          .catch(error => {
            console.log(error)
            this.success = false
            this.errorMessage = error.data.message
            this.$nextTick(() => {
              this.loading = false
              this.alertApiError(error)
            })
          })
    },
    montaPartes (partes = []) {
      for (const parte of partes) {
        const {pessoa, tipo} = parte
        const config = CONFIG_ENTIDADES[tipo.codigo]
        if (!config) continue; // TODO: TEMPORÁRIO! Resolver isso para as tags que não estão em config
        if (!this.processo[config.field]) {
          this.processo[config.field] = []
        }
        pessoa.tmpParteInfo = JSON.parse(JSON.stringify(parte))
        this.processo[config.field].push({...pessoa})
      }
    },
    adicionaEntidadeAoProcesso (entidade, tipo, remover = false) {
      const config = CONFIG_ENTIDADES[tipo]
      if (!entidade.tmpParteInfo) {
        entidade.tmpParteInfo = tipo
      }
      if (!this.id) {
        this.processo[config.field].push({...entidade})
        return
      }
      this.$uloc.notify({
        color: 'warning',
        message: `${remover ? 'Removendo' : 'Vinculando'} ${config.name.toLowerCase()} ${remover ? 'do' : 'ao'} processo, aguarde`
      })
      config.fn(this.id, entidade.id, remover)
          .then(({data}) => {
            this.load()
            this.$uloc.notify({color: 'positive', message: `${config.name} ${remover ? 'removido do' : 'vinculado ao'} processo!`})
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    confirmarAdicionarEntidade (entidade, tipo, remover = false) {
      if (!entidade) return
      const config = CONFIG_ENTIDADES[tipo]
      this.$uloc.dialog({
        title: 'Atenção',
        message: `Tem certeza que deseja ${remover ? 'remover' : 'adicionar'} o ${config.name.toLowerCase()} "${entidade.name}" ${remover ? 'do' : 'ao'} processo?`,
        cancel: true,
        color: 'secondary'
      }).then(data => {
        this.adicionaEntidadeAoProcesso(entidade, tipo, remover)
      }).catch(() => {})
    },
    confirmaRemoverJuiz (data) {
      this.confirmarAdicionarEntidade(data, 'juiz', true)
    },
    confirmaRemoverEscrivao (data) {
      this.adicionaEntidadeAoProcesso(data, 'escrivao', true)
    },
    confirmaRemoverBem (data) {
      this.confirmarAdicionarEntidade(data, 'bem', true)
    },
    confirmaRemoverReu (data) {
      this.confirmarAdicionarEntidade(data, 'reu', true)
    },
    confirmaRemoverAutor (data) {
      this.confirmarAdicionarEntidade(data, 'autor', true)
    },
    confirmaRemoverCliente (data) {
      this.confirmarAdicionarEntidade(data, 'cliente', true)
    },
    adicionarAdvogadoAoProcesso (advogado, type, remover = false) {
      const types = {
        'Reus': 'reu',
        'Autors': 'autor'
      }
      if (!advogado.tmpParteInfo) {
        advogado.tmpParteInfo = `advogado-${types[type]}`
      }
      if (!this.id) {
        this.processo[`advogado${type}`].push({...advogado})
        return
      }
      this.$uloc.notify({color: 'warning', message: `${remover ? 'Removendo' : 'Vinculando'} advogado ${remover ? 'do' : 'ao'} processo, aguarde`})

      adicionaAdvogadoAoProcesso(this.id, advogado.id, types[type], remover)
          .then(({data}) => {
            this.load()
            this.$uloc.notify({color: 'positive', message: `Advogado ${remover ? 'removido do' : 'vinculado ao'} processo!`})
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    confirmaAdicionarAdvogado (advogado, type, remover = false) {
      if (!advogado) return
      this.$uloc.dialog({
        title: 'Atenção',
        message: `Tem certeza que deseja ${remover ? 'remover' : 'adicionar'} o advogado "${advogado.name}" ${remover ? 'do' : 'ao'} processo?`,
        cancel: true,
        color: 'secondary'
      }).then(data => {
        this.adicionarAdvogadoAoProcesso(advogado, type, remover)
      }).catch(() => {})
    },
    confirmaRemoverAdvogado (data, type) {
      this.confirmaAdicionarAdvogado(data, type, true)
    },
    confirmarAdicionarCliente (cliente, type, remover = false) {
      if (!cliente) return
      this.$uloc.dialog({
        title: 'Atenção',
        message: `Tem certeza que deseja ${remover ? 'remover' : 'adicionar'} o cliente "${cliente.name}" ${remover ? 'do' : 'ao'} processo?`,
        cancel: true,
        color: 'secondary'
      }).then(data => {
        // this.$uloc.notify({color: 'warning', message: `${remover ? 'Removendo' : 'Vinculando'} cliente ${remover ? 'do' : 'ao'} processo, aguarde`})
        this.processo.cliente = cliente
        // this.$uloc.notify({color: 'positive', message: `Cliente ${remover ? 'removido do' : 'vinculado ao'} processo!`})
      }).catch(() => {})
    },
    showPerson (id) {
      let router = this.$router || this.router
      let routeData = router.resolve({name: 'pessoa.show', params: {id}})
      window.open(routeData.href, '_blank')
    },
    getInformacoesProcesso () {
      const numero = this.processo.numero?.replace(/\D/g, '') || ''
      if (numero.length !== 20) return
      getInformacoes(numero)
          .then(response => {
            console.log(response)
            if (response.data.tribunal) {
              this.processo.tribunal = response.data.tribunal
              this.loadComarcasByTribunal(this.processo.tribunal)
            }
            if (response.data.comarca) {
              this.cache.comarcas.selected = response.data.comarca
              this.loadVarasByComarca(response.data.comarca)
            }
            this.processo.uf = response.data.uf
            this.processo.ativarRobo = response.data.ativar_robo
            this.processo.tipo = response.data.tipo
          })
          .catch(error => {
            // this.alertApiError(error)
            console.log(error)
          })
    },
    geraNumeracaoAntiga () {
      const numero = this.processo.numero?.replace(/\D/g, '') || ''
      if (numero.length !== 20) return
      const comarca = numero.slice(-4)
      const numeracao = numero.slice(0, 7)
      const ano = numero.slice(9, 13)
      const numeracaoFormatada = numeracao.replace(/(\d{3})(\d{3})(\d)/, '$1.$2-$3')
      this.processo.numeroAntigo = `${comarca}.${ano.slice(-2)}.${numeracaoFormatada}`
    }
  }
}
</script>

<template>
  <window-content v-if="!loading && !errors && !success">
    <div class="m-t">
      <e-row class="erp-row-col-md">
        <e-col style="max-width: 180px">
          <erp-s-field
              view="lr"
              label="ID:"
              label-width="110px"
          >
            <erp-input simple-border v-model="processo.id" disable />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 130px">
          <erp-s-field
              view="lr"
              label="Tribunal:"
          >
            <erp-select placeholder="Selecione"
                        :options="cache.tribunais.list"
                        v-model="processo.tribunal"
                        :loading="cache.tribunais.loading"
                        :disable="cache.tribunais.loading"
                        @input="loadComarcasByTribunal"
            />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 300px">
          <erp-s-field
              view="lr"
              label="Número:"
              label-width="50px"
          >
            <erp-input
                simple-border
                v-model="processo.numero"
                v-mask="'#######-##.####.#.##.####'"
                required
                @keyup="getInformacoesProcesso"
                @blur="geraNumeracaoAntiga"
            />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 300px">
          <erp-s-field
              view="lr"
              label="Número antigo:"
              label-width="90px"
          >
            <erp-input simple-border v-model="processo.numeroAntigo" />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 130px">
          <tipo-processo-select v-model="processo.tipo" label-width="30px" field-view="lr" label="Tipo:"></tipo-processo-select>
        </e-col>
      </e-row>
      <e-row class="erp-row-col-md">
        <e-col style="max-width: 250px">
          <erp-s-field
              view="lr"
              label="Status:"
              label-width="110px"
          >
            <erp-select placeholder="Selecione"
                        :options="cache.statuses.list"
                        v-model="processo.status"
                        :loading="cache.statuses.loading"
                        :disable="cache.statuses.loading"
                        @input="loadClassificacoesByStatus"
            />
          </erp-s-field>
        </e-col>
        <e-col  style="max-width: 250px">
          <erp-s-field
              view="lr"
              label="Classificação:"
          >
            <erp-select placeholder="Selecione"
                        :options="cache.classificacoes.list"
                        v-model="processo.classificacao"
                        :loading="cache.classificacoes.loading"
                        :disable="cache.classificacoes.loading || !processo.status"
            />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row class="erp-row-col-md">
        <e-col style="max-width: 300px">
          <erp-s-field
              view="lr"
              label="Cidade"
              label-width="110px"
          >
            <erp-input simple-border v-model="processo.cidade" />
          </erp-s-field>
        </e-col>
        <e-col  style="max-width: 150px">
          <uf-select field-view="lr" v-model="processo.uf"></uf-select>
        </e-col>
        <e-col style="max-width: 300px">
          <erp-s-field
              view="lr"
              label="Valor da causa"
              label-width="110px"
          >
            <erp-input v-model.lazy="processo.valorCausa" v-money="money" />
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 150px">
          <erp-s-field
              view="lr"
              label="Ativar robô"
              label-width="110px"
          >
            <erp-checkbox v-model="processo.ativarRobo"></erp-checkbox>
          </erp-s-field>
        </e-col>
      </e-row>
    </div>

    <erp-tabs @change="changeTab" :active="tabActive">
      <erp-tab-item name="principal">Principal</erp-tab-item>
      <erp-tab-item name="cliente">Cliente</erp-tab-item>
      <erp-tab-item name="reu">Réu(s)</erp-tab-item>
      <erp-tab-item name="autor">Autor(es)</erp-tab-item>
      <erp-tab-item name="escrivao">Escrivão(ães)</erp-tab-item>
      <!--<erp-tab-item name="historico-status">Histórico de alteração de status</erp-tab-item>-->
    </erp-tabs>
    <window-body class="font-11" tabs>
      <div v-show="tabActive === 'principal'" class="wrapper m-t">
        <e-row class="m-t-n m-b">
          <e-col>
            <div class="m">
              <span class="m-r-md" v-if="processo.createdAt">Cadastro no sistema: {{processo.createdAt.date | formatDate}}</span>
              <span class="m" v-if="processo.dateModified">Última atualização: {{processo.dateModified.date | formatDate}}</span>
            </div>
          </e-col>
        </e-row>
        <e-row>
          <e-col>
            <e-row mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label="Comarca:"
                    label-width="90px"
                >
                  <erp-select placeholder="Selecione"
                              :options="cache.comarcas.list"
                              v-model="cache.comarcas.selected"
                              :loading="cache.comarcas.loading"
                              :disable="cache.comarcas.loading || !processo.tribunal"
                              @input="loadVarasByComarca"
                  />
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label="Vara:"
                    label-width="90px"
                >
                  <erp-select placeholder="Selecione"
                              :options="cache.varas.list"
                              v-model="processo.vara"
                              :disable="cache.varas.loading || !cache.comarcas.selected"
                              :loading="cache.varas.loading"
                  />
                </erp-s-field>
              </e-col>
            </e-row>
<!--            <e-row mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label="Juízo:"
                    label-width="90px"
                >
                  <erp-select placeholder="Selecione"
                              :options="cache.juizos.list"
                              v-model="processo.juizo"
                              :loading="cache.juizos.loading"
                              :disable="cache.juizos.loading"
                  />
                </erp-s-field>
              </e-col>
            </e-row>-->
            <e-row class="m-t">
              <e-col>
                <div style="display: flex; justify-content: space-between">
                  <div class="font-bold m-b-sm">Juízes</div>
                  <div class="m-b-sm" style="max-width: 300px;">
                    <erp-s-field
                        class="font-10 m-r-md"
                        view="lr"
                        label="Adicionar juiz:"
                    >
                      <juiz-input :router="router" v-model="idJuiz" @selecionaJuiz="confirmarAdicionarEntidade($event, 'juiz')" />
                    </erp-s-field>
                  </div>
                </div>
                <table-pessoa-processo :lista="processo.juiz" @showPerson="showPerson" @remove="confirmaRemoverJuiz"></table-pessoa-processo>
              </e-col>
            </e-row>

<!--            <e-row class="m-t">
              <e-col>
                <div style="display: flex; justify-content: space-between">
                  <div class="font-bold m-b-sm">Bens</div>
                  <div class="m-b-sm" style="max-width: 300px;">
                    <erp-s-field
                        class="font-10 m-r-md"
                        view="lr"
                        label="Adicionar bem:"
                    >
                      <bem-input v-model="foo" @changeBem="confirmarAdicionarEntidade($event, 'bem')"></bem-input>
                    </erp-s-field>
                  </div>
                </div>
                <table-bens :bens="processo.bens" @remove="confirmaRemoverBem"></table-bens>
              </e-col>
            </e-row>-->
          </e-col>
        </e-row>
      </div>
      <div v-show="tabActive === 'cliente'" class="wrapper m-t">
        <e-row>
          <e-col>
            <e-row class="m-t">
              <e-col>
                <div style="display: flex; justify-content: space-between">
                  <div class="font-bold m-b-sm">Cliente</div>
                  <div class="m-b-sm" style="max-width: 300px;">
                    <erp-s-field
                        class="font-10 m-r-md"
                        view="lr"
                        label="Adicionar cliente:"
                    >
                      <cliente-processo-input v-model="idCliente" @selecionaCliente="confirmarAdicionarCliente($event, 'cliente')" />
                    </erp-s-field>
                  </div>
                </div>
                <table-pessoa-processo :lista="[processo.cliente]" @showPerson="showPerson" @remove="confirmaRemoverCliente"></table-pessoa-processo>
              </e-col>
            </e-row>

          </e-col>
        </e-row>
      </div>
      <div v-show="tabActive === 'reu'" class="wrapper m-t">
        <e-row>
          <e-col>
            <e-row class="m-t">
              <e-col>
                <div style="display: flex; justify-content: space-between">
                  <div class="font-bold m-b-sm">Réus</div>
                  <div class="m-b-sm" style="max-width: 300px;">
                    <erp-s-field
                        class="font-10 m-r-md"
                        view="lr"
                        label="Adicionar réu:"
                    >
                      <reu-input :router="router" v-model="idReu" @selecionaReu="confirmarAdicionarEntidade($event, 'reu')" />
                    </erp-s-field>
                  </div>
                </div>
                <table-pessoa-processo :lista="processo.reus" @showPerson="showPerson" @remove="confirmaRemoverReu"></table-pessoa-processo>
              </e-col>
            </e-row>

            <e-row class="m-t">
              <e-col>
                <div style="display: flex; justify-content: space-between">
                  <div class="font-bold m-b-sm">Advogados</div>
                  <div class="m-b-sm" style="max-width: 300px;">
                    <erp-s-field
                        class="font-10 m-r-md"
                        view="lr"
                        label="Adicionar advogado:"
                    >
                      <advogado-input :router="router" v-model="idAdvogado" @selecionaAdvogado="confirmaAdicionarAdvogado($event, 'Reus')" />
                    </erp-s-field>
                  </div>
                </div>
                <table-pessoa-processo :lista="processo.advogadoReus" @showPerson="showPerson" @remove="confirmaRemoverAdvogado($event, 'Reus')"></table-pessoa-processo>
              </e-col>
            </e-row>

          </e-col>
        </e-row>
      </div>
      <div v-show="tabActive === 'autor'" class="wrapper m-t">
        <e-row>
          <e-col>
            <e-row class="m-t">
              <e-col>
                <div style="display: flex; justify-content: space-between">
                  <div class="font-bold m-b-sm">Autores</div>
                  <div class="m-b-sm" style="max-width: 300px;">
                    <erp-s-field
                        class="font-10 m-r-md"
                        view="lr"
                        label="Adicionar autor:"
                    >
                      <autor-input :router="router" v-model="idAutor" @selecionaAutor="confirmarAdicionarEntidade($event, 'autor')" />
                    </erp-s-field>
                  </div>
                </div>
                <table-pessoa-processo :lista="processo.autors" @showPerson="showPerson" @remove="confirmaRemoverAutor"></table-pessoa-processo>
              </e-col>
            </e-row>

            <e-row class="m-t">
              <e-col>
                <div style="display: flex; justify-content: space-between">
                  <div class="font-bold m-b-sm">Advogados</div>
                  <div class="m-b-sm" style="max-width: 300px;">
                    <erp-s-field
                        class="font-10 m-r-md"
                        view="lr"
                        label="Adicionar advogado:"
                    >
                      <advogado-input :router="router" v-model="idAdvogadoA" @selecionaAdvogado="confirmaAdicionarAdvogado($event, 'Autors')" />
                    </erp-s-field>
                  </div>
                </div>
                <table-pessoa-processo :lista="processo.advogadoAutors" @showPerson="showPerson" @remove="confirmaRemoverAdvogado($event, 'Autors')"></table-pessoa-processo>
              </e-col>
            </e-row>

          </e-col>
        </e-row>
      </div>
      <div v-show="tabActive === 'escrivao'" class="wrapper m-t">
        <e-row>
          <e-col>
            <e-row class="m-t">
              <e-col>
                <div style="display: flex; justify-content: space-between">
                  <div class="font-bold m-b-sm">Escrivães</div>
                  <div class="m-b-sm" style="max-width: 300px;">
                    <erp-s-field
                        class="font-10 m-r-md"
                        view="lr"
                        label="Adicionar escrivão:"
                    >
                      <escrivao-input :router="router" v-model="idEscrivao" @selecionaEscrivao="confirmarAdicionarEntidade($event, 'escrivao')" />
                    </erp-s-field>
                  </div>
                </div>
                <table-pessoa-processo :lista="processo.escrivaos" @showPerson="showPerson" @remove="confirmaRemoverEscrivao"></table-pessoa-processo>
              </e-col>
            </e-row>

          </e-col>
        </e-row>
      </div>
    </window-body>
    <window-footer class="flex content-between">
      <div class="inline-block window-actions text-right self-end col-grow">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)" />
        <u-btn label="Salvar (F2)" icon="save" icon-type="fa" icon-style="light" flat no-caps
               @click="save"
               v-shortkey="['f2']" @shortkey.native="save" />
      </div>
    </window-footer>
  </window-content>
  <window-success v-else-if="success">
    <div class="text-center">
      <u-icon name="check-circle" color="positive" type="fa" icon-style="solid" class="success-circle-140" />
    </div>
    <h5 v-if="!id" class="m-t">Processo registrado com sucesso!</h5>
    <h5 v-else class="m-t">Processo atualizado com sucesso!</h5>
    <div class="text-center m-t">
      <timer-close-window ref="timer" @end="$uloc.window.close($root.wid)" />
    </div>
  </window-success>
  <window-loading :errors="errors" :status="status" v-else></window-loading>
</template>
