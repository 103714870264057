import {
    adicionaAutorAoProcesso,
    adicionaBemAoProcesso,
    adicionaEscrivaoAoProcesso,
    adicionaJuizAoProcesso,
    adicionaReuAoProcesso
} from "../../../../../domain/processos/services";

export const CONFIG_ENTIDADES = {
    juiz: {
        name: 'Juiz',
        field: 'juiz',
        fn: adicionaJuizAoProcesso
    },
    escrivao: {
        name: 'Escrivão',
        field: 'escrivaos',
        fn: adicionaEscrivaoAoProcesso
    },
    reu: {
        name: 'Réu',
        field: 'reus',
        fn: adicionaReuAoProcesso
    },
    autor: {
        name: 'Autor',
        field: 'autors',
        fn: adicionaAutorAoProcesso
    },
    bem: {
        name: 'Bem',
        field: 'bems',
        fn: adicionaBemAoProcesso
    },
    'advogado-reu': {
        name: 'Advogado do réu',
        field: 'advogadoReus'
    },
    'advogado-autor': {
        name: 'Advogado do autor',
        field: 'advogadoAutors'
    },
    /* temp */
    'adv-1': {
        name: 'Adv. 1',
        field: 'envolvidos'
    },
    'adv-2': {
        name: 'Adv. 2',
        field: 'envolvidos'
    }
}