<template>
  <helper-input-btn>
    <erp-input
        ref="input"
        @input="__set"
        slot="input"
        simple-border
        v-model="description"
        @keydown.enter="searchAdvogado"
        readonly
    >
    </erp-input>
    <a @click="searchAdvogado"><i class="erp-icon search min"></i> </a>
    <u-icon name="plus-circle" type="fa" icon-style="light" class="m-l-xs cursor-pointer" @click.native="abrirCadastro" />
  </helper-input-btn>
</template>

<script>
import InputMixin from '../../../../../reuse/input/mixin'
import {HelperInputBtn} from 'uloc-vue-plugin-erp'
import searchAdvogado from '../window/search/SearchAdvogado'
import windowAdvogado from "../window/windowAdvogado";

export default {
  name: 'AdvogadoInput',
  mixins: [InputMixin],
  props: ['router'],
  data () {
    return {
      findedData: null,
      description: null
    }
  },
  components: {HelperInputBtn},
  methods: {
    __updateInternal() {
      this.updateInternal((v) => v.id + ' - ' + v.descricao)
      this.$emit('selecionaAdvogado', this.findedData)
    },
    abrirCadastro () {
      let router = this.$router || this.router
      let routeData = router.resolve({name: 'pessoas', query: {tags: 'advogado'}})
      window.open(routeData.href, '_blank')
    },
    searchAdvogado,
    windowAdvogado
  }
}
</script>
