<template>
  <div>
    <div class="col-grow flex justify-between h-full">
      <e-window-table
          class="col-grow h-full h-limited"
          :columns="['ID', 'Nome', 'Opções']"
          style=""
      >
        <e-tr v-for="(data, index) in list" :key="index">
          <e-td><span>{{ String(data.id).padStart(4, '0') }}</span></e-td>
          <e-td>{{ data.name }}</e-td>
          <e-td>
            <a @click="showPerson(data.id)"><i class="erp-icon search min"></i> </a>
            <u-icon name="times" type="fa" icon-style="regular" class="text-negative text-md m-l cursor-pointer m-b-xs" @click.native="remove(data)"></u-icon>
          </e-td>
        </e-tr>
        <e-tr v-if="!list || !list.length">
          <e-td style="text-align: left !important;" colspan="100%">Nenhum registro</e-td>
        </e-tr>
      </e-window-table>
    </div>
  </div>
</template>

<script>
import {
  EWindowTable,
  ETd,
  ETr
} from 'uloc-vue-plugin-erp'

export default {
  name: "TablePessoaProcesso",
  props: {
    lista: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  components: {
    EWindowTable,
    ETd,
    ETr
  },
  computed: {
    list () {
      return (this.lista || []).filter(item => item)
    }
  },
  methods: {
    remove (data) {
      console.log(data)
      this.$emit('remove', data)
    },
    showPerson (id) {
      this.$emit('showPerson', id)
    }
  }
}
</script>

<style scoped>
.h-limited {
  max-height: 200px;
  overflow: auto;
}
</style>