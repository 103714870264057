<template>
  <helper-input-btn>
    <erp-input
        ref="input"
        @input="__set"
        slot="input"
        simple-border
        v-model="description"
        @keydown.enter="searchJuiz"
        readonly
    >
    </erp-input>
    <a @click="searchJuiz"><i class="erp-icon search min"></i> </a>
    <u-icon name="plus-circle" type="fa" icon-style="light" class="m-l-xs cursor-pointer" @click.native="abrirCadastro" />
  </helper-input-btn>
</template>

<script>
import InputMixin from '../../../../../reuse/input/mixin'
import {HelperInputBtn} from 'uloc-vue-plugin-erp'
import searchJuiz from '../window/search/SearchJuiz'
import windowJuiz from "../window/windowJuiz";

export default {
  name: 'JuizInput',
  mixins: [InputMixin],
  props: ['router'],
  data () {
    return {
      findedData: null,
      description: null
    }
  },
  components: {HelperInputBtn},
  methods: {
    __updateInternal() {
      this.updateInternal((v) => v.id + ' - ' + v.descricao)
      this.$emit('selecionaJuiz', this.findedData)
    },
    abrirCadastro () {
      let router = this.$router || this.router
      let routeData = router.resolve({name: 'pessoas', query: {tags: 'juiz'}})
      window.open(routeData.href, '_blank')
    },
    searchJuiz,
    windowJuiz
  }
}
</script>
