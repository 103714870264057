export function isReadyToSubmit (obj, fields = [], callbackErrorMessage = field => {}) {
    let isValid = true
    for (const field of fields) {
        const tmpfield = field.indexOf(':') !== -1 ? field.split(':')[0] : field
        let value = obj[tmpfield];
        if (typeof value !== 'undefined' && value !== null) continue;
        callbackErrorMessage(field)
        isValid = false
        break;
    }
    return isValid
}

export function turnCamelCaseStringReadable (string) {
    return string.replace(/([A-Z])/g, ' $1').replace(/^\w/, w => w.toUpperCase())
}
